export const environment = {
  production: true,
  dexUrl: 'https://data-explorer.dev.zsservices.com',
  config: {
    serverUrl: 'https://pac.dev.zsservices.com',
    loginUrl:
      'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/login.aspx?ReturnUrl=',
    gtagId: 'G-73SFRWXQ6Y',
    client: 'dev',
  },
  authEnabled: false,
  authConfig: {
    issuer: 'https://federationdev.abbvie.com',
    redirectUri: 'https://pac.dev.zsservices.com/callback2',
    clientId: 'mfa.cdldev',
    responseType: 'code',
    cookieDomain: 'zsservices.com',
  },
};
