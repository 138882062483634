import { Component, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchBarData } from '@core/models';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sider',
  templateUrl: './sider.component.html',
  styleUrls: ['./sider.component.scss'],
})
export class SiderComponent implements AfterViewInit {
  @Input() siderData?: LaunchBarData;

  @Input() menuItemsList: RdSiderMenuItem[] = [];

  authEnabled = environment.authEnabled;

  showHelpModal = false;

  readonly releaseVersion = 'R12.1';

  readonly contactSupportList = [
    {
      label: 'Email',
      value: 'support@zs.com',
      href: 'mailto:support@zs.com',
    },
    {
      label: 'Phone',
      value: 'U.S.: 877-393-9775',
      href: 'tel:8773939775',
    },
  ];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngAfterViewInit() {
    if (this.authEnabled) document.body.classList.add('auth-enabled');
  }

  toggleSupportModal($event: boolean): void {
    this.showHelpModal = $event;
  }

  handleBrandClick(): void {
    this.router.navigateByUrl('/');
  }

  logoutClick() {
    this.authenticationService.tryLogout();
  }
}
