import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-select-project-modal',
  templateUrl: './select-project-modal.component.html',
  styleUrls: ['./select-project-modal.component.scss'],
})
export class SelectProjectModalComponent {
  constructor(public modalService: ModalService, private http: HttpClient) {}

  ngOnInit() {
    // fetch user details api is called to fetch the name of the current user
    this.fetchUserDetails();
  }
  @Input() projectDropdownList: any;
  @Input() isProjectListLoaded: any;
  @Input() selectedProjectId: any;
  @Output() handleSelectProjects = new EventEmitter<void>();

  // user name displayed in the project modal
  public userName = '';

  //Styles for project dropdown
  selectProjectStyle = {
    width: '100%',
    'font-size': '16px',
    'font-weight': '400',
    color: '#454250',
    'line-height': '24px',
  };

  //opens create project modal and closes select project modal
  handleCreateNewProjectButton() {
    this.modalService.closeSelectProjectModal();
    this.modalService.openCreateProjectModal();
  }

  fetchUserDetails() {
    //PAC Home endpoint to fetch user details
    const url_endpoint = '/user';

    //GET API call to PAC Home to fetch the current user details
    this.http.get(url_endpoint).subscribe((res: any) => {
      //Setting the displayed user name in the popup
      this.userName = res.user_detail.name;
    });
  }
}
