import { Injectable } from '@angular/core';
import { App } from '@core/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private _insights: App[] = [];

  private _adminPanel: App[] = [];

  private _dataManagement: App[] = [];

  private _analytics: App[] = [];

  private _isBusinessUser: Boolean = false;

  public get insights(): App[] {
    return this._insights;
  }
  public set insights(value: App[]) {
    this._insights = [...value];
  }

  public get adminPanel(): App[] {
    return this._adminPanel;
  }

  public set adminPanel(value: App[]) {
    this._adminPanel = [...value];
  }

  public get dataManagement(): App[] {
    return this._dataManagement;
  }

  public set dataManagement(value: App[]) {
    this._dataManagement = [...value];
  }

  public get analytics(): App[] {
    return this._analytics;
  }

  public set analytics(value: App[]) {
    this._analytics = [...value];
  }

  public get isBusinessUser(): Boolean {
    return this._isBusinessUser;
  }

  public set isBusinessUser(value: Boolean) {
    this._isBusinessUser = value;
  }

  regionChange = new Subject<string>();

  getRegion = new Subject<string>();
}
