import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Environment } from '@core/models';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  API_SUCCESS,
  ApiEndpoints,
  SOMETHING_WENT_WRONG,
} from '@core/constants';
import { HomeService } from '@core/services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() projectDropdownList: any;
  @Input() selectedProjectId: any;
  @Output() handleSelectProjects = new EventEmitter<void>();

  metadata = (environment as Environment)?.metadata;
  pageName = 'Patient Analytics Cloud';
  selectRegionStyle = {
    width: '94px',
    height: '32px',
    'font-size': '16px',
    'font-weight': '400',
    color: '#454250',
    'line-height': '24px',
  };
  selectProjectStyle = {
    width: '150px',
    'font-size': '16px',
    'font-weight': '400',
    color: '#454250',
    'line-height': '24px',
  };
  selectedRegion = 'US';
  selectRegionOptions = ['US', 'JP', 'US PAC'];
  environment = environment.config.client;
  errorMessage = '';
  currentRoute = '';

  constructor(
    private http: HttpClient,
    private home: HomeService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateCurrentRoute();
      });

    this.updateCurrentRoute(); // Initial route update

    this.home.getRegion.subscribe((region: string) => {
      this.selectedRegion = region;
    });
  }

  private updateCurrentRoute(): void {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    this.currentRoute = route.snapshot.url
      .map((segment) => segment.path)
      .join('/');

    // Update pageName based on the current route
    if (this.currentRoute.split('/')[0] === 'reports') {
      this.pageName = 'Published Documents';
    } else {
      this.pageName = 'Patient Analytics Cloud';
    }
  }

  updateRegion(event: any) {
    this.selectedRegion = event;
    let payload = {
      region: this.selectedRegion,
    };
    this.http.post(ApiEndpoints.SAVE_REGION, payload).subscribe({
      next: (response: any) => {
        if (response?.status === API_SUCCESS.toLowerCase()) {
          this.home.regionChange.next(this.selectedRegion);
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
        }
      },
      error: (error) => {
        if (error?.status !== 401) {
          this.errorMessage = SOMETHING_WENT_WRONG;
        }
      },
    });
  }
}
