<ng-container *ngIf="!showErrorMessage">
  <app-create-project-modal *ngIf="isLoggedInUser"></app-create-project-modal>
  <app-select-project-modal
    [projectDropdownList]="projectDropdownList"
    [selectedProjectId]="selectedProjectId"
    [isProjectListLoaded]="isProjectListLoaded"
    (handleSelectProjects)="handleSelectProjects($event)"
  ></app-select-project-modal>

  <app-sider
    [menuItemsList]="sideBarMenuItemsList"
    [siderData]="launchBarData"
  ></app-sider>
  <div class="app-layout">
    <app-header
      [projectDropdownList]="projectDropdownList"
      [selectedProjectId]="selectedProjectId"
      (handleSelectProjects)="handleSelectProjects($event)"
    ></app-header>
    <ng-container *ngIf="!isLoading; else loadingSpinner">
      <div class="app-content" *ngIf="!errorMessage">
        <router-outlet></router-outlet>
      </div>
      <div class="place-items-center-absolute" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
    </ng-container>
  </div>
  <ng-template #loadingSpinner>
    <div class="place-items-center-absolute">
      <rd-spin></rd-spin>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="showErrorMessage">
  <div>
    {{ authErrorMessage }}
  </div>
</ng-container>
