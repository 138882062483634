<rd-modal
  [isVisible]="modalService.isCreateProjectModalOpen"
  [isCloseable]="false"
  [isMaskClosable]="false"
  modalWidth="680px"
>
  <ng-container ngProjectAs="[rdModalTitle]">
    <h1 class="project-heading" style="width: 100%; text-align: center">
      Create A New Project
    </h1>
  </ng-container>
  <ng-container ngProjectAs="[rdModalContent]">
    <form [formGroup]="projectForm" (ngSubmit)="handleSubmit()">
      <span
        style="
          display: flex;
          justify-content: space-between;
          margin: 0px 0px 2px 0px;
          align-items: end;
        "
      >
        <span
          style="display: flex; justify-content: center; align-items: center"
        >
          <p style="margin-bottom: 0px">
            Enter project name <span style="color: red">*</span>
          </p>
          <span
            class="info-icon"
            aria-label="info"
            [attr.data-tooltip]="infoIconText.projectName"
            >i</span
          >
        </span>
      </span>

      <input
        id="projectName"
        placeholder="Project Name"
        formControlName="projectName"
        class="dropdownStyle"
      />
      <div
        *ngIf="
          projectForm.get('projectName')?.touched &&
          projectForm.get('projectName')?.invalid
        "
        style="color: red; font-size: 12px"
      >
        <span *ngIf="projectForm.get('projectName')?.errors?.['required']"
          >Project Name is required.</span
        >
        <span *ngIf="projectForm.get('projectName')?.errors?.['minlength']"
          >Project Name must be at least 4 characters.</span
        >
        <span *ngIf="projectForm.get('projectName')?.errors?.['maxlength']"
          >Project Name must be at most 50 characters.</span
        >
        <span *ngIf="projectForm.get('projectName')?.errors?.['pattern']"
          >Project Name must be alphanumeric and no spaces at the
          beginning.</span
        >
        <span
          *ngIf="projectForm.get('projectName')?.errors?.['projectNameTaken']"
        >
          Project Name is already taken.
        </span>
      </div>

      <span
        style="
          display: flex;
          justify-content: space-between;
          margin: 14px 0px 2px 0px;
          align-items: end;
        "
      >
        <span
          style="display: flex; justify-content: center; align-items: center"
        >
          <p style="margin-bottom: 0px">
            Enter project description <span style="color: red">*</span>
          </p>
          <span
            class="info-icon"
            aria-label="info"
            [attr.data-tooltip]="infoIconText.projectDesc"
            >i</span
          >
        </span>
      </span>

      <textarea
        id="projectDesc"
        placeholder="Project Description"
        formControlName="projectDescription"
        [rows]="2"
        [maxLength]="100"
        class="dropdownStyle"
      ></textarea>
      <div
        *ngIf="
          projectForm.get('projectDescription')?.touched &&
          projectForm.get('projectDescription')?.invalid
        "
        style="color: red; font-size: 12px; position: relative; top: -5px"
      >
        <span
          *ngIf="projectForm.get('projectDescription')?.errors?.['required']"
          >Project Description is required.</span
        >
        <span
          *ngIf="projectForm.get('projectDescription')?.errors?.['maxlength']"
          >Project Description must be at most 100 characters.</span
        >
        <span *ngIf="projectForm.get('projectDescription')?.errors?.['pattern']"
          >Project Description must be alphanumeric.</span
        >
      </div>

      <span
        style="
          display: flex;
          justify-content: space-between;
          margin: 14px 0px 2px 0px;
          align-items: end;
        "
      >
        <span
          style="display: flex; justify-content: center; align-items: center"
        >
          <p style="margin-bottom: 0px">
            Enter project code <span style="color: red">*</span>
          </p>
          <span
            class="info-icon"
            aria-label="info"
            [attr.data-tooltip]="infoIconText.projectCode"
            >i</span
          >
        </span>
      </span>
      <input
        id="projectCode"
        placeholder="Project Code"
        formControlName="projectCode"
        class="dropdownStyle"
      />
      <div
        *ngIf="
          projectForm.get('projectCode')?.touched &&
          projectForm.get('projectCode')?.invalid
        "
        style="color: red; font-size: 12px"
      >
        <span *ngIf="projectForm.get('projectCode')?.errors?.['required']"
          >Project Code is required.</span
        >
        <span *ngIf="projectForm.get('projectCode')?.errors?.['pattern']"
          >Project Code must be 10 alphanumeric characters.</span
        >
      </div>
      <span
        style="
          display: flex;
          justify-content: space-between;
          margin: 14px 0px 2px 0px;
          align-items: end;
        "
      >
        <span
          style="display: flex; justify-content: center; align-items: center"
        >
          <p style="margin-bottom: 0px">Resource <span style="color: red">*</span></p>
          <span
            class="info-icon"
            aria-label="info"
            [attr.data-tooltip]="infoIconText.resource"
            >i</span
          >
        </span>
      </span>
      <rd-select
        placeholder="Please select resource"
        contentNotFound="No resource found"
        [showSearchBox]="true"
        [allowClear]="true"
        [selectedItems]="selectedResourceList"
        (selectedItemsChange)="handleResourceListChange($event)"
        [isKeyLabelOptions]="true"
        [options]="resourceList"
        [selectStyle]="dropdownStyle"
        [mode]="'default'"
        selectSize="large"
      ></rd-select>

      <span
        style="
          display: flex;
          justify-content: space-between;
          margin: 14px 0px 2px 0px;
          align-items: end;
        "
      >
        <span
          style="display: flex; justify-content: center; align-items: center"
        >
          <p style="margin-bottom: 0px">Select users</p>
          <span
            class="info-icon"
            aria-label="info"
            [attr.data-tooltip]="infoIconText.userList"
            >i</span
          >
        </span>
      </span>
      <rd-select
        [maxTag]="6"
        placeholder="Please select users"
        contentNotFound="No user found"
        [showSearchBox]="true"
        [allowClear]="true"
        [selectedItems]="selectedUsers"
        (selectedItemsChange)="handleUserListChange($event)"
        [isKeyLabelOptions]="true"
        [options]="userList"
        [selectStyle]="dropdownStyle"
        selectSize="large"
        [mode]="'multiple'"
      ></rd-select>

      <span
        style="
          display: flex;
          justify-content: space-between;
          margin: 14px 0px 2px 0px;
          align-items: end;
        "
      >
        <span
          style="display: flex; justify-content: center; align-items: center"
        >
          <p style="margin-bottom: 0px">
            Upload configuration file
            <a href="/assets/configReference.pdf" target="_blank"
              >(Reference)</a
            >
          </p>
          <span
            class="info-icon"
            aria-label="info"
            [attr.data-tooltip]="infoIconText.defaultConfigText"
            >i</span
          >
        </span>
        <a (click)="downloadDefaultConfiguration()" #downloadLink>
          Download default configuration</a
        >
      </span>
      <div class="upload-container">
        <div
          class="upload-box"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)"
          (click)="fileInput.click()"
          [class.active]="isDragOver"
        >
          <rd-icon
            theme="outline"
            class="upload-icon"
            type="download"
          ></rd-icon>
          <p>Click or drag file to this area to upload</p>
          <p class="hint">Only .csv files are accepted</p>
          <input
            #fileInput
            type="file"
            (change)="onFileSelected($event)"
            accept=".csv"
            hidden
          />
        </div>
        <div *ngIf="file" class="file-info">
          <span>
            <rd-icon
              theme="outline"
              class="clip-icon"
              type="paper-clip"
            ></rd-icon
            >{{ file.name }}</span
          >
          <span (click)="removeFile()">
            <rd-icon
              theme="outline"
              class="delete-button"
              type="delete"
            ></rd-icon>
          </span>
        </div>
      </div>

      <div style="display: flex; width: 100%; justify-content: space-between">
        <rd-button
          btnType="default"
          btnText="Go Back"
          style="margin-top: 18px"
          (click)="handleBackButton($event)"
          type="button"
        ></rd-button>
        <rd-button
          btnType="primary"
          btnText="Submit"
          style="margin-top: 18px"
          type="submit"
          [isDisabled]="!this.projectForm.valid || this.selectedResourceList == null"
        ></rd-button>
      </div>
    </form>
    <span style="color: green; font-size: 14px">{{ message }} </span>
    <span style="color: red; font-size: 14px" *ngIf="displayUnexpectedError"
      >ERROR: An unexpected error has occured. Please refresh the page and try
      again.
    </span>
  </ng-container>
  <ng-container ngProjectAs="[rdModalFooter]"> </ng-container>
</rd-modal>
