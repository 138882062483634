export function convertJsonToCsv(jsonData: any[]): string {
  const keys = Object.keys(jsonData[0]);
  const csvRows = [];

  // Add the header row
  csvRows.push(keys.join(','));

  // Add the data rows
  for (const data of jsonData) {
    const values = keys.map((key) => {
      const value = data[key];
      if (typeof value === 'object' && value !== null) {
        return `"${JSON.stringify(value).replace(/"/g, '""')}"`; // Escape double quotes within the string
      }
      return `"${value}"`;
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
}


export function convertCsvToJson(csvData: string, headers:any[]) {
  const lines = csvData.split('\n');
  const result = [];

 

  for (let i = 1; i < lines.length; i++) {
    const currentline = lines[i].split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);

    if (currentline.length === headers.length) {
      const obj: any = {};
      for (let j = 0; j < headers.length; j++) {
        let value = currentline[j]?.trim() || '';

        // Check if the value is a stringified JSON object or array
        if (value.startsWith('"') && value.endsWith('"')) {
          value = value.slice(1, -1).replace(/""/g, '"');
          try {
            value = JSON.parse(value);
          } catch (error) {
            // If parsing fails, keep it as a string
            console.warn(`Could not parse JSON value for ${headers[j]}: ${value}`);
          }
        }

        obj[headers[j]] = value;
      }
      result.push(obj);
    } else {
      console.warn('Skipping malformed line:', lines[i]);
    }
  }
  return result;
}


