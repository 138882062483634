<rd-modal
  [isVisible]="modalService.isSelectProjectModalOpen"
  [isCloseable]="false"
  [isMaskClosable]="false"
  modalWidth="620px"
>
  <ng-container ngProjectAs="[rdModalTitle]">
    <h1 class="project-heading">Hi {{ this.userName }}</h1>
    <h1 class="project-heading">Welcome to Patient Analytics Hub</h1>
  </ng-container>
  <ng-container ngProjectAs="[rdModalContent]">
    <div>
      <rd-spin *ngIf="!isProjectListLoaded"></rd-spin>
    </div>
    <div style="display: flex">
      <div style="flex-grow: 1; margin: 0 20px 0 0" [style.padding-bottom]="!modalService.isAdmin ? '20px' : '0px'">
        <rd-select
          *ngIf="isProjectListLoaded"
          placeholder="Please select a project"
          [selectedItems]="selectedProjectId"
          (selectedItemsChange)="handleSelectProjects.emit($event)"
          [isKeyLabelOptions]="true"
          [options]="projectDropdownList"
          [selectStyle]="selectProjectStyle"
          selectSize="large"
          [mode]="'default'"
          [selectStyle]="{ width: '100%' }"
        ></rd-select>
      </div>
      <rd-button
        btnType="primary"
        btnText="Next"
        [isDisabled]="!modalService.isProjectSelected"
        (click)="modalService.closeSelectProjectModal()"
      ></rd-button>
      
    </div>

    <p
      style="width: 100%; text-align: center; margin: 16px"
      *ngIf="modalService.isAdmin"
    >
      OR
    </p>
    <rd-button
      style="width: 100%"
      btnType="primary"
      btnText="Create a new project"
      [btnStyle]="{ width: '100%' }"
      (click)="handleCreateNewProjectButton()"
      *ngIf="modalService.isAdmin"
    ></rd-button>
  </ng-container>
  <ng-container ngProjectAs="[rdModalFooter]"> </ng-container>
</rd-modal>
