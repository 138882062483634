import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  // Whether the select project modal is open or not
  public isSelectProjectModalOpen = true;

  // If a project is selected or not
  public isProjectSelected = false;

  // Whether the create project modal is open or not
  public isCreateProjectModalOpen = false;

  public isAdmin = false;

  public userDetails:any = null;

  constructor() {}

  setIsAdmin(val:boolean){
    this.isAdmin = val
  }

  setUser(user:any){
    this.userDetails = user
  }

  // Toggle the state of the select project modal
  toggleSelectProjectModal() {
    this.isSelectProjectModalOpen = !this.isSelectProjectModalOpen;
  }

  // Open the select project modal
  openSelectProjectModal() {
    this.isSelectProjectModalOpen = true;
  }

  // Sets isProjectSelected to true
  setIsProjectSelectedToTrue() {
    this.isProjectSelected = true;
  }

  // Close the select project modal
  closeSelectProjectModal() {
    this.isSelectProjectModalOpen = false;
  }

  // Toggle the state of the create project modal
  toggleCreateProjectModal() {
    this.isCreateProjectModalOpen = !this.isCreateProjectModalOpen;
  }

  // Open the create project modal
  openCreateProjectModal() {
    this.isCreateProjectModalOpen = true;
  }

  // Close the create project modal
  closeCreateProjectModal() {
    this.isCreateProjectModalOpen = false;
  }
}
