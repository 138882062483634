import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AppState } from 'src/app/shared/services/app-state';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  OAuthHost = environment.authConfig.issuer;

  appStateValues: any = {};

  constructor(
    private authenticationService: AuthenticationService,
    private appState: AppState,
    private cookieService: CookieService
  ) {
    AppState.appState.subscribe((val: any) => {
      if (val && Object.keys(val).length > 0) {
        this.appStateValues = val;
      } else {
        this.appStateValues = {};
      }
    });
  }

  public separateUrlAndEndpoint(url: string) {
    const index = url.indexOf('.com');
    if (index !== -1) {
      const baseUrl = url.slice(0, index + 4);
      const endpoint = url.slice(index + 4);
      return { baseUrl, endpoint };
    } else {
      return { baseUrl: '', endpoint: url };
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.custom_request(request)).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error.status === 401) {
            if (environment.authEnabled) {
              this.appState.clearGlobalState();
              this.authenticationService.login();
            } else {
              window.open(
                `${environment.config.loginUrl}${window.location.origin}`,
                '_self'
              );
            }
          }
        },
      })
    );
  }

  custom_request(request: any) {
    console.log('This is actual URL');
    console.log(request.url);
    let url = request.url;

    if (url.includes(this.OAuthHost) && environment.authEnabled) {
      console.log('Entering if with url');
      console.log(url);
      const reqClone = request.clone({});
      return reqClone;
    } else {
      let { baseUrl, endpoint } = this.separateUrlAndEndpoint(request.url);
      let url = endpoint;
      console.log('Entering else with url');
      console.log(url);
      let headers = request.headers;
      if (environment.authEnabled) {
        headers = headers
          .set('Content-Type', 'application/json')
          .set(
            'Authorization',
            `Bearer ${
              this.cookieService.get(
                this.authenticationService.accessTokenKey
              ) || ''
            }`
          )
          .set(
            'X-ID-Token',
            `Bearer ${
              this.cookieService.get(this.authenticationService.idTokenKey) ||
              ''
            }`
          );
      }
      if (baseUrl != '') {
        url = request.url;
      } else {
        url = `${environment.config.serverUrl}/${request.url}`;
      }
      
      //explicitly adding all cookies in headers 
      const allCookies = this.cookieService.getAll();
      headers['Cookie'] = allCookies

      const reqClone = request.clone({
        url: url,
        withCredentials: true,
        headers: headers,
      });
      return reqClone;
    }
  }
}
