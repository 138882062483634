<rd-header appName="{{ pageName }}">
  <ng-container rd-header-content>
    <div class="header-content">
      <div *ngIf="metadata" class="header__metadata">
        <img
          [src]="'assets/' + metadata.logo"
          height="40"
          *ngIf="metadata.logo"
        />
        <p *ngIf="metadata.tagline">{{ metadata.tagline }}</p>
      </div>
      <div
        style="margin-left: 10px"
        *ngIf="environment === 'demo' || environment === 'dev'"
      >
        <rd-select
          [selectStyle]="selectRegionStyle"
          [selectedItems]="selectedRegion"
          (selectedItemsChange)="updateRegion($event)"
          [options]="selectRegionOptions"
          [isBorderless]="true"
          [mode]="'default'"
        ></rd-select>
      </div>
      <rd-select
        placeholder="Please select an option"
        [selectedItems]="selectedProjectId"
        (selectedItemsChange)="handleSelectProjects.emit($event)"
        [isKeyLabelOptions]="true"
        [options]="projectDropdownList"
        [isBorderless]="true"
        [selectStyle]="selectProjectStyle"
        selectSize="large"
        [mode]="'default'"
      ></rd-select>
    </div>
  </ng-container>
</rd-header>
