<div class="reportPage">
  <rd-breadcrumb [breadcrumbData]="breadcrumbsData" (breadcrumbClick)="handleBreadcrumbClick($event)"></rd-breadcrumb>
  <div class="wrapperReports" *ngIf="!errorMessage">
    <rd-table
      [isLoading]="!isFetched"
      [tableColumns]="tableColumns"
      [tableData]="tableData"
      [showPagination]="false"
      [frontEndPagination]="false"
      (tableRowClick)="handleRowClick($event)"
    ></rd-table>
  </div>
  <div class="place-items-center-absolute" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
