import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@core/interceptors';

import {
  RDButtonModule,
  RDHeaderModule,
  RDModalModule,
  RDSelectModule,
  RDSiderModule,
  RDSpinModule,
  RDInputModule,
  RDBreadcrumbModule,
  RDUploadModule,
  RDIconModule,
  RDTableModule,
  RDDividerModule,
  RDTooltipModule,
} from '@zs-ca/angular-cd-library';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './layout/header/header.component';
import { SiderComponent } from './layout/sider/sider.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ReportsComponent } from './reports/reports.component';
import { SelectProjectModalComponent } from './components/select-project-modal/select-project-modal.component';
import { CreateProjectModalComponent } from './components/create-project-modal/create-project-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SiderComponent,
    NotFoundComponent,
    ReportsComponent,
    SelectProjectModalComponent,
    CreateProjectModalComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    RDButtonModule,
    RDHeaderModule,
    RDModalModule,
    RDSiderModule,
    RDInputModule,
    RDIconModule,
    RDSpinModule,
    RDSelectModule,
    RDDividerModule,
    RDUploadModule,
    RDBreadcrumbModule,
    RDTableModule,
    RDTooltipModule,
    RDTableModule,
    NzIconModule,
    NzButtonModule,
    NzInputModule,
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useFactory: () => en_US,
      deps: [LOCALE_ID],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
