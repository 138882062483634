import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import {
  API_SUCCESS,
  ApiEndpoints,
  SOMETHING_WENT_WRONG,
} from '@core/constants';
import { LaunchBarApiResponse, LaunchBarData } from '@core/models';
import { AnalyticsService } from '@core/services';
import { ModalService } from '@shared/services/modal.service';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/shared/services/app-state';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  errorMessage = '';

  isLoggedInUser = false;
  isLoading = false;

  // whether the list of projects is loaded or not
  public isProjectListLoaded = false;

  // List displayed in dropdown should be in this format
  public projectDropdownList = [];

  // Selected Project ID from the dropdown
  public selectedProjectId = null;

  launchBarData?: LaunchBarData;

  public sideBarMenuItemsList: RdSiderMenuItem[] = [
    { label: 'Insights', path: '/home/insights', icon: 'bulb' },
    { label: 'Analytics', path: '/home/analytics', icon: 'fund' },
    {
      label: 'Data Management',
      path: '/home/data-management',
      icon: 'database',
    },
  ];

  appStateValues: any = {};
  currentUser: any = null;
  showErrorMessage = true;
  authErrorMessage: any = 'Authenticating, Please wait...';
  appStateSubs: any;
  authServiceSubs: any;
  pageInitialized = false;

  constructor(
    private analytics: AnalyticsService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private authService: AuthenticationService,
    private appState: AppState,
    private cookieService: CookieService,
    public modalService: ModalService
  ) {
    this.analytics.initialize();

    this.router.events
      .pipe(filter((e: any) => e instanceof RouterEvent))
      .subscribe({
        next: (e) => {
          this.navigationInterceptor(e as RouterEvent);
        },
      });
  }

  fetchUserDetails() {
    // PAC Home endpoint to fetch user details
    const url_endpoint = 'logged_in_user';
    let userDetails: any;
    let isAdmin = false;

    this.http.get(url_endpoint).subscribe({
      next: (res: any) => {
        userDetails = res.data;
        this.modalService.setUser(userDetails);

        isAdmin = (userDetails['user_role'] == 'Admin')
        this.modalService.setIsAdmin(isAdmin);
        this.isLoggedInUser = true
        if (isAdmin) {
          this.sideBarMenuItemsList = [
            ...this.sideBarMenuItemsList,
            {
              label: 'Admin Panel',
              path: '/home/admin-panel',
              icon: 'control',
            },
          ];
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  ngOnInit(): void {
    this.fetchUserDetails();
    if (environment.authEnabled) {
      this.authenticateUser();
      this.appStateSubs = AppState.appState.subscribe((val: any) => {
        if (val && Object.keys(val).length > 0) {
          this.appStateValues = val;
        } else {
          this.appStateValues = {};
        }
        if (!this.pageInitialized) {
          if (this.cookieService.get(this.authService.accessTokenKey)) {
            this.showErrorMessage = false;
            this.fetchAuthData();
          } else {
            const code = this.getUrlParam('code');
            if (code === 'undefined' || !code) {
              this.authService.login();
            } else if (code) {
              this.router.navigate([], {
                queryParams: {
                  yourParamName: null,
                  youCanRemoveMultiple: null,
                },
                queryParamsHandling: 'merge',
              });
              this.authService.getAccessToken(code);
            }
          }
        }
        this.pageInitialized = true;
      });
    } else {
      this.showErrorMessage = false;
      this.fetchAuthData();
    }
    //Fetches list of project from Data Explorer
    this.fetchProjectsData();
  }

  authenticateUser() {
    this.authServiceSubs = this.authService.authResponse.subscribe((res) => {
      if (res['valid']) {
        this.showErrorMessage = false;
        this.fetchAuthData();
      } else {
        this.showErrorMessage = true;
        this.authErrorMessage = res['message'] || 'Something went wrong.';
      }
    });
  }

  //Updates selectedProjectId variable and cookies on dropdown select
  handleSelectProjects(projectId: any) {
    this.selectedProjectId = projectId;
    this.cookieService.set(
      'project_id',
      projectId || '',
      environment.authConfig.cookieDomain
    );
    // this.modalService.closeSelectProjectModal();
    this.modalService.setIsProjectSelectedToTrue();
  }

  //Fetches saved projectId from cookies
  getSavedProjectId() {
    const savedProjectId = this.cookieService.get('project_id');
    if (savedProjectId != null && savedProjectId != '') {
      // if the project_id is present, make it as a default select in the dropdown
      this.selectedProjectId = savedProjectId;
      this.modalService.setIsProjectSelectedToTrue();
      this.modalService.closeSelectProjectModal();
    } else {
      // if the project_id is not present, show the select project pop up
      this.modalService.openSelectProjectModal();
    }
  }

  // Fetches the list of projects from data explorer
  fetchProjectsData() {
    // set projectListLoaded as false to show the spinning loader
    this.isProjectListLoaded = false;

    //End point to fetch the list of projects
    const endpoint = `projects`;

    //GET API call to fetch project list from data explorer
    this.http.get(endpoint).subscribe((res: any) => {
      //Mapping the API result to the format expected in Dropdown
      if (res.status == 'success') {
        this.projectDropdownList = res.data.map((d: any) => ({
          label: d.project_name,
          value: `${d.project_id}`,
        }));
      }
      //set projectListLoaded as true to hide the loader and show the dropdown
      this.isProjectListLoaded = true;

      //Once the project list is populated, fetch the saved project Id from cookies
      this.getSavedProjectId();
    });
  }

  private fetchAuthData(): void {
    this.isLoading = true;
    this.http.get<LaunchBarApiResponse>(ApiEndpoints.LAUNCH_BAR).subscribe({
      next: (response) => {
        if (response?.status === API_SUCCESS) {
          this.launchBarData = { ...response?.applications };
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
        }
        this.isLoading = false;
      },
      error: (error) => {
        if (error?.status !== 401) {
          this.errorMessage = SOMETHING_WENT_WRONG;
          this.isLoading = false;
        }
      },
    });
  }

  private getTitle() {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['title']) {
        return child.snapshot.data['title'];
      } else {
        return 'Home - Patient Analytics Cloud';
      }
    }
    return 'Home - Patient Analytics Cloud';
  }

  /**
   * Show & hide the loading spinner during RouterEvent changes.
   *
   * @param {RouterEvent} event the router event.
   */
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    } else if (event instanceof NavigationEnd) {
      this.title.setTitle(this.getTitle());
      this.isLoading = false;
    } else if (
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.isLoading = false;
    }
  }

  getUrlParam(key: string) {
    const results = new RegExp('[?&]' + key + '=([^&#]*)').exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }

  ngOnDestroy() {
    this.appStateSubs?.unsubscribe();
    this.authServiceSubs?.unsubscribe();
  }
}
