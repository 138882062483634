import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { AppState } from './app-state';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  timerHandler: any;

  accessTokenKey = 'AccessToken';
  refreshTokenKey = 'RefreshToken';
  idTokenKey = 'ID-Token';
  codeVerifierKey = 'codeVerifier';

  OAuthHost = environment.authConfig.issuer;
  scope =
    'profile_contact openid profile profile_location mfa profile_sales profile_network given_name profile_name profile_org offline_access profile_group profile_email email dc group';

  OAuthEndpoint = {
    Authorization: `${this.OAuthHost}/as/authorization.oauth2`,
    Token: `${this.OAuthHost}/as/token.oauth2`,
    TokenRevocation: `${this.OAuthHost}/as/revoke_token.oauth2`,
    TokenIntrospection: `${this.OAuthHost}/as/introspect.oauth2`,
    UserDetails: `${this.OAuthHost}/idp/userinfo.openid`,
    SignOff: `${this.OAuthHost}/idp/startSLO.ping`,
  };

  public authSubject = new Subject<any>();
  public authResponse = this.authSubject.asObservable();

  appStateValues: any = {};

  constructor(
    private appState: AppState,
    public http: HttpClient,
    private cookieService: CookieService
  ) {
    AppState.appState.subscribe((val: any) => {
      if (val && Object.keys(val).length > 0) {
        this.appStateValues = val;
      } else {
        this.appStateValues = {};
      }
    });
  }

  login() {
    this.tryLogin();
  }

  /**
   * Begins the process to attain new access token as we don't have one.
   */
  attainNewAccessCode() {
    try {
      if (environment.authEnabled) {
        const codeVerifier = this.strRandom(128);
        localStorage.setItem(this.codeVerifierKey, codeVerifier);
        const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(
          CryptoJS.enc.Base64
        );
        const codeChallenge = codeVerifierHash
          .replace(/=/g, '')
          .replace(/\+/g, '-')
          .replace(/\//g, '_');
        const clientId = environment.authConfig.clientId;
        const redirectWebUri = environment.authConfig.redirectUri;
        const authorizeURL =
          `${this.OAuthEndpoint.Authorization}?client_id=${clientId}&` +
          `redirect_uri=${redirectWebUri}&` +
          `response_type=code&` +
          `code_challenge_method=S256&` +
          `code_challenge=${codeChallenge}` +
          `&scope=${encodeURI(this.scope)}` +
          `&state=xyzabc`;
        window.location.href = authorizeURL;
      }
    } catch (error) {
      console.log(error);
    }
  }

  getAccessToken(code: string) {
    try {
      if (environment.authEnabled) {
        const tokenOAuthURL = this.OAuthEndpoint.Token;
        const userDetailOauthURL = this.OAuthEndpoint.UserDetails;
        const params = new HttpParams({
          fromObject: {
            grant_type: 'authorization_code',
            client_id: environment.authConfig.clientId,
            code_verifier: localStorage.getItem(this.codeVerifierKey) || '',
            code: code,
            redirect_uri: environment.authConfig.redirectUri,
          },
        });
        const postHttpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        };
        this.postMethodWithHeader(
          tokenOAuthURL,
          params,
          postHttpOptions
        ).subscribe(
          (res: any) => {
            this.cookieService.set(
              this.accessTokenKey,
              res['access_token'],
              environment.authConfig.cookieDomain
            );
            this.cookieService.set(
              this.refreshTokenKey,
              res['refresh_token'],
              environment.authConfig.cookieDomain
            );
            this.cookieService.set(
              this.idTokenKey,
              res['id_token'],
              environment.authConfig.cookieDomain
            );
            this.getUserDetails(userDetailOauthURL, res);
            this.registerRefreshTimer(res);
          },
          (err: any) => {
            console.log(err);
            this.notifyError(false);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  getUserDetails(userDetailOauthURL: string, response: any) {
    const userHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + response['access_token'],
      }),
    };
    this.getMethodWithHeader(userDetailOauthURL, userHeaders).subscribe(
      (userDetails: any) => {
        this.appState.setGlobalState(
          'userDetails',
          JSON.stringify(userDetails)
        );
        this.notifyError(true);
      },
      (err: any) => {
        console.log(err);
        this.notifyError(false);
      }
    );
  }

  registerRefreshTimer(response: any) {
    try {
      const interval =
        (((response && response.expires_in) || 3600) - 5 * 60) * 1000;
      this.timerHandler = setInterval(() => {
        this.refreshAccessToken();
      }, interval);
    } catch (error) {
      console.log(error);
    }
  }

  refreshAccessToken() {
    try {
      const tokenOAuthURL = this.OAuthEndpoint.Token;

      const params = new HttpParams({
        fromObject: {
          client_id: environment.authConfig.clientId,
          access_token: this.cookieService.get(this.accessTokenKey),
          grant_type: 'refresh_token',
          refresh_token: this.cookieService.get(this.refreshTokenKey),
        },
      });
      const postHttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      this.postMethodWithHeader(
        tokenOAuthURL,
        params,
        postHttpOptions
      ).subscribe(
        (res: any) => {
          this.cookieService.set(
            this.accessTokenKey,
            res['access_token'],
            environment.authConfig.cookieDomain
          );
          this.cookieService.set(
            this.refreshTokenKey,
            res['refresh_token'],
            environment.authConfig.cookieDomain
          );
        },
        (err: any) => {
          console.log(err);
          this.notifyError(false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  tryLogout() {
    try {
      const tokenOAuthURL = this.OAuthEndpoint.TokenRevocation;
      const params = new HttpParams({
        fromObject: {
          client_id: environment.authConfig.clientId,
          token: this.appStateValues['accessToken'],
          token_type_hint: 'access_token',
        },
      });
      this.appState.clearGlobalState();
      this.cookieService.clear(
        this.accessTokenKey,
        environment.authConfig.cookieDomain
      );
      this.cookieService.clear(
        this.refreshTokenKey,
        environment.authConfig.cookieDomain
      );
      const postHttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      this.postMethodWithHeader(
        tokenOAuthURL,
        params,
        postHttpOptions
      ).subscribe(
        (res: any) => {
          window.location.href = this.OAuthEndpoint.SignOff;
        },
        (err: any) => {
          console.log(err);
          this.notifyError(false);
        }
      );
      window.location.href = this.OAuthEndpoint.SignOff;
    } catch (error) {
      console.log(error);
    }
  }

  private tryLogin() {
    this.attainNewAccessCode();
  }

  notifyError(errorFlag: boolean, message = '', logout = false) {
    const resp = {
      valid: errorFlag,
      message: message,
      logout: logout,
    };
    this.authSubject.next(resp);
  }

  postMethodWithHeader(
    url_key: string,
    request_payload: object,
    header: object
  ): Observable<Object> {
    return this.http.post(url_key, request_payload, header);
  }

  getMethodWithHeader(url_key: string, header: object): Observable<Object> {
    return this.http.get(url_key, header);
  }

  private strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
