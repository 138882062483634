import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  isFetched = false;
  s3Path = '';
  currentFolder = '';
  currentPath: string[] = [];
  breadcrumbsData = [{ navigationPath: '.', displayName: '' }];
  errorMessage = '';
  tableColumns = [
    {
      name: 'Name',
      key: 'name',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '33%',
      align: 'left',
      showIcon: false,
      sortable: true,
      type: 'clickable',
    },
    {
      name: 'Type',
      key: 'type',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '33%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },

    {
      name: 'Actions',
      key: 'actions',
      dataType: 'string',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '33%',
      showIcon: false,
      type: 'actions',
      align: 'center',
      sortable: false,
      actionIcons: [
        {
          type: 'eye',
          key: 'view',
          title: 'View',
          theme: 'outline',
        },
        {
          type: 'download',
          key: 'export',
          title: 'Export',
          theme: 'outline',
        },
      ],
    },
  ];
  tableData = [] as any;
  fetchedData = {} as any;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchCurrentPathData(this.currentPath);
  }

  fetchCurrentPathData(currentPath: string[]) {
    //POST API call with current Path
    this.errorMessage = '';
    let currentPathPayload = currentPath.join('/');
    let payload = { folder: currentPathPayload };
    this.http.post('get-reports', payload).subscribe({
      next: (res: any) => {
        if (res?.status === 'SUCCESS') {
          this.isFetched = true;
          this.fetchedData = res;
          this.tableData = this.formatData(res);
          console.log({tableData:this.tableData})
          this.s3Path = res.data_location;
          this.breadcrumbsData[0].displayName = res.data_location;
        } else {
          console.log(res);
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  handleRowClick(e: any) {
    const isFile = e?.data?.is_file;
    if (isFile) {
      const action = e?.icon?.type === 'download' ? 'download' : 'view';
      //API To download the file
      this.downloadFile(e?.data?.name, action);
    } else {
      const folder = e?.data?.name;
      this.isFetched = false;
      //Add new folder path
      this.changeCurrentFolder(folder);

      // //Add current Folder to breadcrumbs
      this.addFolderToBreadcrumbs(folder);

      // //Fetch the details for the selected folder
      this.displayCurrentFolderDetails();
    }
  }

  changeCurrentFolder(folder: string) {
    this.currentFolder = folder;
    this.currentPath = [...this.currentPath, folder];
  }

  addFolderToBreadcrumbs(folder: string) {
    this.breadcrumbsData = [
      ...this.breadcrumbsData,
      { navigationPath: '.', displayName: folder },
    ];
  }

  displayCurrentFolderDetails() {
    //fetch new data
    const data = this.fetchCurrentPathData(this.currentPath);

    //set new data to be dispayed
    this.fetchedData = data as any;
  }

  formatData(fetchedData: any) {
    let tempData = fetchedData?.data;
    let folders = [] as any;
    let files = [] as any;
    if (!tempData.length) {
      this.errorMessage = "You don't have any objects in this folder.";
    }

    tempData.forEach((item: any) => {
      if (item.is_file) {
        item['visibleActionIcons'] = ['export', 'view'];
        item['disabledActionIcons'] = [];
        files = [...files, item];
      } else {
        item['visibleActionIcons'] = [];
        item['disabledActionIcons'] = [];
        folders = [...folders, item];
      }
    });
    return [...folders, ...files];
  }

  handleBreadcrumbClick(event: any) {
    this.isFetched = false;
    this.currentFolder = event.displayName;
    this.removeBreadcrumbsData(event.displayName);
  }

  removeBreadcrumbsData(folder: string) {
    if (folder === this.s3Path) {
      this.breadcrumbsData = [
        { navigationPath: '.', displayName: this.s3Path },
      ];
      this.currentPath = [];
    } else {
      const filteredBreadCrumbsData = [];
      const filteredFolders = [];
      for (const item of this.breadcrumbsData) {
        filteredBreadCrumbsData.push(item);
        if (item.displayName === folder) {
          break;
        }
      }
      for (const item of this.currentPath) {
        filteredFolders.push(item);
        if (item === folder) {
          break;
        }
      }
      this.currentPath = filteredFolders;
      this.breadcrumbsData = filteredBreadCrumbsData;
    }
    this.displayCurrentFolderDetails();
  }

  downloadFile(filename: string, action: string) {
    let currentFolderPath = this.currentPath.join('/');
    let payload = {};
    if (currentFolderPath === '') {
      payload = { folder: filename, action };
    } else {
      payload = { folder: `${currentFolderPath}/${filename}`, action };
    }
    this.http.post('download-report', payload).subscribe({
      next: (res: any) => {
        if (res?.status === 'SUCCESS') {
          window.open(res?.url, '_blank');
        } else {
          console.log(res);
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
